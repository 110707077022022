import _ from 'lodash';
import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import {
  getBuild,
  deleteBuild,
  duplicateBuild,
  getBuildComments,
  postBuildComment,
  deleteBuildComment,
  likeBuild,
  showModal,
} from '../../../actions';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';

import Build from '../../ui/Build';
import ReadMore from '../../ui/ReadMore';

import BuildImages from '../image';
import AthleteLine from '../../athlete/line';
import AddActivity from '../addActivity';
import AddOthers from '../addOthers';

import Comments from '../../comments';

import Heart from '../../reactions/heart';
import Heartable from '../../reactions/heartable';
import Share from '../../reactions/share';
import Bookmark from '../../reactions/bookmark';

import ActivityCard from '../../builds/activityCard';
import BuildItemsList from '../../builds/items/list';

import {
  EllipsisHorizontalIcon,
  EllipsisHorizontalCircleIcon,
  BoltIcon,
  PlusCircleIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

import RecordEvent from '../../analytics/pageview';

import Loader from '../../Loader';
import Glassup from '../../landing/glassup';

import APArrowLeftIcon from '../../ui/ArrowLeftIcon';

import Helmet from '../../ui/Helmet';
import GuideTooltip from '../../ui/Guide';
import RelatedBuilds from '../relatedBuilds';

const ViewBuild = ({
  builds,
  lists,
  isSignedIn,
  isAdmin,
  auth,
  getBuild,
  deleteBuild,
  duplicateBuild,
  getBuildComments,
  postBuildComment,
  deleteBuildComment,
  likeBuild,
  showModal,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    const { state } = location;
    const skipEdit = state ? state.skipEdit : false;
    if (skipEdit) {
      navigate(-3);
    } else {
      navigate(-1);
    }
  };

  // on mount, getBuild
  const [mounted, setMounted] = React.useState(false);
  const [currentBuild, setCurrentBuild] = React.useState(null);
  const [expanded, setExpanded] = React.useState([
    0,
    1,
    2,
    3,
    'Frame',
    'Parts',
    'Accessories',
    'Apparel',
    'Nutrition',
    'MainBuild',
  ]);

  useEffect(() => {
    if (!mounted || currentBuild !== id) {
      setMounted(true);
      setCurrentBuild(id);
      getBuild(id);
      getBuildComments(id);
    }
  }, [mounted, id, currentBuild, getBuild, isSignedIn, getBuildComments]);

  const build = builds[id];
  const athleteId = build?._athlete?._id;
  const isOwner = build && auth.athlete === athleteId;

  const handleExpand = (index) => {
    const newExpanded = [...expanded];
    if (newExpanded.includes(index)) {
      const indexToRemove = newExpanded.indexOf(index);
      newExpanded.splice(indexToRemove, 1);
    } else {
      newExpanded.push(index);
    }
    setExpanded(newExpanded);
  };

  const renderSourceUri = () => {
    if (!build) {
      return null;
    }

    const { sourceUri } = build;
    if (!sourceUri) {
      return null;
    }

    // format sourceUri to show only domain
    const url = new URL(sourceUri);
    const { hostname } = url;

    return (
      <a
        href={sourceUri}
        className="flex flex-row w-fit items-center hover:underline text-gray-500"
        target="_blank"
        rel="noreferrer"
      >
        {hostname}
      </a>
    );
  };

  const renderLinks = () => {
    if (!build) {
      return null;
    }

    const { links } = build;
    if (!links || links?.length === 0) {
      return null;
    }

    return (
      <div className="flex flex-col mb-8">
        <div className="flex flex-col gap-1">
          {links.map((link) => {
            const { _id, name, uri } = link;
            return (
              <a
                href={uri}
                key={_id}
                className="flex flex-row w-fit items-center underline underline-offset-4 text-sm"
                target="_blank"
                rel="noreferrer"
              >
                {name}
              </a>
            );
          })}
        </div>
      </div>
    );
  };

  const postComment = async (comment) => {
    await postBuildComment(build._id, comment);
  };

  const deleteComment = async (commentId) => {
    await deleteBuildComment(build._id, commentId);
  };

  const renderProfiles = (build) => {
    if (!build || !build._athlete) {
      return null;
    }

    const { _athlete, profiles } = build;

    profiles?.sort((a, b) => {
      return a.profileType === 'event' ? -1 : 1;
    });

    // count the number of profiles
    const count = profiles?.length;

    // is there an event profile?
    const hasEvent =
      profiles?.find((profile) => profile.profileType === 'event') ||
      build?._athlete?.profileType === 'event';

    return (
      <div className="flex flex-row gap-2 items-center mb-4 mt-6">
        {isOwner ? (
          <div
            className="px-1 hover:cursor-pointer hover:bg-base-100 rounded-md flex flex-row items-center"
            onClick={() => showModal('ADD_OTHERS', { buildId: build?._id })}
          >
            {count > 0 ? (
              <EllipsisHorizontalCircleIcon className="h-6 w-6 -ml-1.5 text-neutral dark:text-secondary" />
            ) : (
              <>
                <div className="relative mt-1">
                  <GuideTooltip
                    message={`Add ${
                      hasEvent ? 'other' : 'event and other'
                    } participants`}
                    placement="tooltip-right"
                  >
                    <PlusCircleIcon className="h-6 w-6 -ml-1.5 text-neutral dark:text-secondary relative" />
                  </GuideTooltip>
                </div>
              </>
            )}

            <AthleteLine
              athlete={_athlete}
              profiles={profiles}
              buildId={build._id}
              noLink={true}
            />
          </div>
        ) : (
          <AthleteLine
            athlete={_athlete}
            profiles={profiles}
            buildId={build._id}
          />
        )}
      </div>
    );
  };

  // handle double click on image
  const handleDoubleClick = () => {
    isSignedIn && likeBuild(id);
  };

  const renderBuild = () => {
    const {
      name,
      description,
      _id,
      activity,
      links,
      items,
      _likes,
      images,
      variantBuilds,
    } = build ? build : {};

    // is the build liked by the current user
    const liked = _likes?.includes(auth?.athlete);

    return (
      <div className="flex flex-col w-full sm:flex-row lg:mt-3 order-last lg:order-2 lg:w-10/12 gap-4">
        <div className="sm:w-6/12 relative">
          <div className="sticky top-2">
            {images?.length ? (
              <Heartable action={handleDoubleClick} liked={liked}>
                <BuildImages build={build} />
              </Heartable>
            ) : isOwner ? (
              <Link
                to={`/build/edit/${_id}`}
                state={{ backable: true }}
                className="w-full h-96 bg-base-200 flex items-center justify-center"
              >
                Add an image to your build
              </Link>
            ) : (
              <Loader type="placeholder" height="h-96" width="w-full" />
            )}
          </div>
        </div>
        <div className="px-4 sm:w-6/12">
          {_id ? (
            <div className="flex items-center justify-between -ml-1 mb-2">
              <div className="flex items-center space-x-1 mt-2 sm:mt-0">
                <Heart build={build} />
                <Bookmark build={build} lists={lists} right />
                <Share build={build} />
              </div>
            </div>
          ) : (
            <div className="-mt-1 mb-1">
              <Loader type="placeholder" height="h-6" width="w-1/4" />
            </div>
          )}
          {name ? <h1>{name}</h1> : <Loader type="placeholder" height="h-4" />}
          {_id ? (
            <ReadMore className="my-3">{description}</ReadMore>
          ) : (
            <Loader type="placeholderParagraph" height="h-3" />
          )}
          {renderSourceUri()}

          {renderProfiles(build)}

          {!_.isEmpty(build?.buildType) || build?.electric || build?.forSale ? (
            <div className="flex flex-row gap-2 items-center mt-6 w-full flex-wrap">
              {!_.isEmpty(build?.buildType) && (
                <>
                  {Object.values(build.buildType).map((type, index) => {
                    return (
                      <Link
                        key={index}
                        className="badge badge-accent p-3"
                        to={`/search/builds/type:${type.value}`}
                      >
                        {type.label}
                      </Link>
                    );
                  })}
                </>
              )}
              {build?.electric ? (
                <div className="badge badge-accent py-3">
                  <BoltIcon className="h-4 w-4 mr-1" /> Electric
                </div>
              ) : null}
              {build?.forSale ? (
                <div className="badge badge-ghost py-3 bg-brand text-black">
                  <CurrencyDollarIcon className="h-4 w-4 mr-1" /> For Sale
                </div>
              ) : null}
            </div>
          ) : null}
          {isOwner && (
            <AddOthers
              buildId={build?._id}
              build={build}
              buildProfiles={build?.profiles}
            />
          )}
          {(!_.isEmpty(items) || isOwner || isAdmin) && (
            <div
              className="flex flex-row justify-between items-center cursor-pointer mt-6"
              onClick={() => handleExpand(0)}
            >
              <h2 className="text-start mb-2">Build</h2>
              <div className="flex flex-row items-center">
                <ChevronDownIcon
                  className={`h-7 w-7 transform ease-in duration-200 ${
                    expanded.includes(0) ? `rotate-0` : `rotate-180`
                  }`}
                />
              </div>
            </div>
          )}
          {expanded.includes(0) && (
            <BuildItemsList
              lists={lists}
              build={build}
              isOwner={isOwner}
              isAdmin={isAdmin}
            />
          )}

          <ActivityCard
            activity={activity}
            isOwner={isOwner}
            onTitleClick={() => handleExpand(1)}
            expanded={expanded.includes(1)}
          />

          {variantBuilds?.length > 1 && (
            <div
              className="flex flex-row justify-between items-center cursor-pointer mt-6"
              onClick={() => handleExpand('MainBuild')}
            >
              <h2 className="text-start mb-2">Versions</h2>
              <div className="flex flex-row items-center">
                <ChevronDownIcon
                  className={`h-7 w-7 transform ease-in duration-200 ${
                    expanded.includes('MainBuild') ? `rotate-0` : `rotate-180`
                  }`}
                />
              </div>
            </div>
          )}
          {expanded.includes('MainBuild') &&
            variantBuilds?.length > 1 &&
            variantBuilds?.map((variant) => {
              return (
                <div
                  key={variant._id}
                  className="flex flex-row py-1 items-start"
                >
                  <Link
                    to={`/build/${variant._id}`}
                    className={
                      variant._id === _id
                        ? 'badge bg-brand py-3'
                        : 'badge badge-accent py-3'
                    }
                  >
                    {variant.name}
                  </Link>
                </div>
              );
            })}

          {!_.isEmpty(links) && (
            <div
              className="flex flex-row justify-between items-center cursor-pointer mt-6"
              onClick={() => handleExpand(2)}
            >
              <h2 className="text-start mb-2">Links</h2>
              <div className="flex flex-row items-center">
                <ChevronDownIcon
                  className={`h-7 w-7 transform ease-in duration-200 ${
                    expanded.includes(2) ? `rotate-0` : `rotate-180`
                  }`}
                />
              </div>
            </div>
          )}
          {expanded.includes(2) && renderLinks()}

          {isSignedIn && (
            <div
              className="flex flex-row justify-between items-center cursor-pointer mt-6"
              onClick={() => handleExpand(3)}
            >
              <h2 className="text-start mb-2">Comments</h2>
              <div className="flex flex-row items-center">
                <ChevronDownIcon
                  className={`h-7 w-7 transform ease-in duration-200 ${
                    expanded.includes(3) ? `rotate-0` : `rotate-180`
                  }`}
                />
              </div>
            </div>
          )}
          {_id && expanded.includes(3) && (
            <Comments
              build={build}
              comments={build?.comments}
              athlete={auth?.profile}
              isSignedIn={isSignedIn}
              postAction={postComment}
              deleteAction={deleteComment}
            />
          )}
        </div>
      </div>
    );
  };

  const handleDeleteBuild = async (id) => {
    // are you sure?
    if (window.confirm('Are you sure you want to delete this build?')) {
      await deleteBuild(id);
    }
  };

  const handleDuplicateBuild = async (id) => {
    if (window.confirm('Are you sure you want to duplicate this build?')) {
      await duplicateBuild(id);
    }
  };

  const renderEditButton = () => {
    if (
      !auth ||
      !builds ||
      !id ||
      !auth.athlete ||
      !builds[id]?._athlete?._id
    ) {
      return null;
    }
    // render only if user is logged in and is the owner of the build
    if (auth.athlete === builds[id]._athlete._id) {
      return (
        <>
          <li>
            <Link to={`/build/edit/${id}`} state={{ backable: true }}>
              Edit
            </Link>
          </li>
          <li>
            <button onClick={() => handleDuplicateBuild(id)}>Duplicate</button>
          </li>
        </>
      );
    }
  };

  const renderDeleteButton = () => {
    if (
      !auth ||
      !builds ||
      !id ||
      !auth.athlete ||
      !builds[id]?._athlete?._id
    ) {
      return null;
    }
    // render only if user is logged in and is the owner of the build
    if (auth.athlete === builds[id]._athlete._id) {
      return (
        <li>
          <button onClick={() => handleDeleteBuild(id)}>Delete</button>
        </li>
      );
    }
  };

  const renderMenu = () => {
    const { state } = location;
    const backable = state ? state.backable : false;

    return (
      <div className="flex items-center justify-between my-1 ml-3 sm:mx-0 lg:flex-col">
        {backable && (
          <Link
            className="btn btn-square btn-ghost"
            onClick={() => handleBackButton()}
          >
            <APArrowLeftIcon />
          </Link>
        )}
      </div>
    );
  };

  return (
    <>
      <Glassup>
        <Build>
          <Helmet
            title={`${build?.name}, a bike build by ${build?._athlete?.name} on Active Projects`}
            description={build?.description}
            url={`${process.env.REACT_APP_URL}/build/${build?._id}`}
          />
          <div className="flex flex-col order-1 w-fit">{renderMenu()}</div>
          {renderBuild()}
          <div className="flex flex-col order-2 lg:order-3 items-center">
            <div className="dropdown dropdown-bottom dropdown-end mr-4 sm:-mr-1 sm:pr-0">
              <label
                tabIndex={0}
                className="btn btn-square btn-ghost self-center cursor-pointer mt-2"
              >
                <EllipsisHorizontalIcon className="h-10 w-10" />
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box w-36"
              >
                {renderEditButton()}
                {isOwner && renderDeleteButton()}
                {!isOwner && (
                  <li>
                    <div
                      onClick={() =>
                        showModal('SUPPORT', {
                          comment: `My suggested change for <b>${build?._athlete?.name}'s ${build?.name}</b> is:`,
                        })
                      }
                    >
                      Suggest Edit
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Build>
        <RecordEvent
          title={`Build - ${build?.name}`}
          hitType="pageview"
          page={`/build/${build?._id}`}
        />
        <AddActivity
          buildId={build?._id}
          build={build}
          buildActivity={build?.activity}
        />
        <RelatedBuilds builds={builds} buildId={id} lists={lists} />
      </Glassup>
    </>
  );
};

function mapStateToProps(state) {
  const isAdmin = state.auth?.permissions?.admin || false;

  return {
    auth: state.auth,
    isSignedIn: state.auth?.userId,
    isAdmin,
  };
}

export default connect(mapStateToProps, {
  getBuild,
  deleteBuild,
  duplicateBuild,
  getBuildComments,
  postBuildComment,
  deleteBuildComment,
  likeBuild,
  showModal,
})(ViewBuild);
