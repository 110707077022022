import buildTypeDefinitions from '../../../utils/buildTypeDefinitions.json';
import React from 'react';
import BuildForm from '../../ui/FormPage';

// Form imports
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import CustomInput from '../../forms/CustomInput';
import CustomRichTextarea from '../../forms/CustomRichTextarea';
import CustomToggle from '../../forms/CustomToggle';
import CustomSelectMulti from '../../forms/CustomSelectMulti';

import ImageUpload from '../../forms/ImageUpload';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { connect } from 'react-redux';
import {
  postBuild,
  getBuild,
  deleteBuildImage,
  deleteBuildItem,
} from '../../../actions';
import { withRouter } from '../../withRouter';

class EditBuild extends React.Component {
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getBuild(this.props.match.params.id);
    }
  }

  onSubmit = async (formValues) => {
    await this.props.postBuild(formValues);
    // don't return until postBuild is complete and loader is hidden
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!this.props.loader?.form) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  };

  handleDeleteImage = async (imageFile) => {
    await this.props.deleteBuildImage(this.props.match.params.id, imageFile);
  };

  handleDisableField = (index, values) => {
    // if id exists, then it's an existing item and should be disabled
    if (values[index]._id) {
      return true;
    }
  };

  renderForm() {
    // get the build id from the url or set to _new_
    const buildId =
      this.props.match && this.props.match.params
        ? this.props.match.params.id
        : '_new_';
    // set initial values to the build if it exists
    const initialValues =
      this.props.build && this.props.build[buildId]
        ? // ? this.props.build[buildId]
          {
            // only include the fields we want to edit
            _id: this.props.build[buildId]._id,
            id: this.props.build[buildId]._id,
            name: this.props.build[buildId].name,
            description: this.props.build[buildId].description,
            buildType: this.props.build[buildId].buildType,
            electric: this.props.build[buildId].electric,
            forSale: this.props.build[buildId].forSale,
            images: this.props.build[buildId].images,
            items: this.props.build[buildId].items,
            links: this.props.build[buildId].links,
          }
        : { id: buildId };
    // if no items on build, add default with empty values
    if (!initialValues.items || initialValues.items.length < 1) {
      initialValues.items = [
        {
          component: 'equipment',
          type: 'frame',
          make: '',
          model: '',
          year: '',
        },
      ];
    }

    const initLink = {
      name: '',
      uri: '',
    };

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        validate={(formValues) => {
          const errors = {};

          // if form is submitting, don't validate
          if (this.props.loader?.form) {
            return errors;
          }

          if (!formValues.name) {
            errors.name = 'Please enter a name for your build';
          }

          if (!formValues.images || formValues.images.length < 1) {
            errors.images = 'Please upload at least one image';
          }

          if (!formValues.buildType || formValues.buildType.length < 1) {
            errors.buildType = 'Please select a build type';
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row pl-0 pr-4 mt-4 items-center justify-between sm:px-0">
              <div className="flex flex-row justify-start px-5 sm:px-0 sm:pt-1">
                <h1>{buildId && buildId !== '_new_' ? 'Edit' : 'Add'} Build</h1>
              </div>
            </div>
            <div className="flex flex-col my-4">
              <Field
                name="images"
                component={ImageUpload}
                labelStyle="pl-4 sm:pl-0"
                handleDeleteImage={this.handleDeleteImage}
                submitting={submitting}
              />
            </div>
            <div className="flex flex-col my-4 px-4 sm:px-0 md:max-w-lg">
              <Field
                name="name"
                component={CustomInput}
                type="text"
                placeholder="e.g. My Sirrus Carbon or Dusty, the Mountain Bike"
                label="Name for your build"
                disabled={submitting}
              />
            </div>
            <div className="flex flex-col my-4 px-4 sm:px-0 md:max-w-lg">
              <Field
                name="description"
                component={CustomRichTextarea}
                type="text"
                placeholder="Description"
                label="Description"
              />
            </div>
            <div className="flex flex-col my-4 px-4 sm:px-0 md:max-w-lg">
              <Field
                name="buildType"
                component={CustomSelectMulti}
                options={buildTypeDefinitions}
                label="Build Type"
              />
            </div>
            <div className="flex flex-col my-4 px-4 sm:px-0 md:max-w-lg">
              <Field
                name="electric"
                component={CustomToggle}
                type="checkbox"
                label="Electric"
              />
            </div>
            <div className="flex flex-col my-4 px-4 sm:px-0 md:max-w-lg">
              <Field
                name="forSale"
                component={CustomToggle}
                type="checkbox"
                label="For Sale"
              />
            </div>
            {/* Array of links with name and uri */}
            <div className="flex flex-col px-4 w-full sm:px-0 sm:flex-col gap-1 md:max-w-lg">
              <FieldArray
                name="links"
                validate={(values) => {
                  const errors = [];

                  if (values && values.length > 0) {
                    values.forEach((link, index) => {
                      const linkErrors = {};
                      if (!link.name) {
                        linkErrors.name = 'Please enter a name for your link';
                      }
                      const url = link.uri;
                      const pattern = new RegExp(
                        '^(https?:\\/\\/)?' + // protocol
                          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                          '(\\#[-a-z\\d_]*)?$',
                        'i'
                      ); // fragment locator
                      if (!pattern.test(url)) {
                        linkErrors.uri = 'Please enter a valid URL';
                      }
                      errors[index] = linkErrors;
                    });
                  }
                  return errors;
                }}
              >
                {({ fields }) => (
                  <>
                    {fields?.length > 0 && <h2>Links</h2>}
                    {fields.map((name, index) => (
                      <div key={index} className="flex flex-col my-2">
                        <div className="flex flex-col sm:flex-row justify-between gap-4">
                          <div className="flex flex-col">
                            <Field
                              name={`${name}.name`}
                              component={CustomInput}
                              type="text"
                              placeholder="e.g. Strava"
                              label="Name"
                            />
                          </div>
                          <div className="flex flex-col">
                            <Field
                              name={`${name}.uri`}
                              component={CustomInput}
                              type="text"
                              placeholder="https://"
                              label="Reference link"
                            />
                          </div>
                          <div className="flex flex-row px-0 sm:flex-col sm:items-end gap-1 sm:self-end">
                            <button
                              type="button"
                              className="btn btn-ghost btn-square"
                              onClick={() => fields.remove(index)}
                            >
                              <XMarkIcon className="h-6 w-6" />
                            </button>
                            {/* <button
                              type="button"
                              className={`btn btn-ghost ${
                                index !== fields.length - 1 && `hidden`
                              }`}
                              onClick={() =>
                                fields.push({
                                  ...initLink,
                                })
                              }
                              disabled={index !== fields.length - 1}
                            >
                              Add Link
                            </button> */}
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* {(!fields || fields.length === 0) && ( */}
                    <button
                      type="button"
                      className={`btn btn-ghost btn-link w-fit btn-sm underline-offset-4 px-0 disabled:bg-base-100 disabled:cursor-not-allowed`}
                      onClick={() =>
                        fields.push({
                          ...initLink,
                        })
                      }
                    >
                      Add Link
                    </button>
                    {/* )} */}
                  </>
                )}
              </FieldArray>
            </div>
            <div className="flex flex-col my-4 px-4 sm:px-0 md:max-w-lg">
              <button
                className={`btn btn-primary w-32`}
                type="submit"
                disabled={submitting || pristine}
              >
                <span
                  className={
                    submitting
                      ? 'loading loading-spinner loading-sm flex'
                      : 'hidden'
                  }
                />
                {submitting ? 'Saving...' : 'Save'}
              </button>
              <Field name="id" component={CustomInput} type="hidden" />
            </div>
          </form>
        )}
      />
    );
  }

  render() {
    return (
      <BuildForm>
        {/* {this.props.loader.form ? <Loader type="inline" size="full"/> : this.renderForm()} */}
        {this.renderForm()}
      </BuildForm>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    athlete: state.athlete,
    build: state.builds.builds,
    loader: state.loader,
  };
}

export default connect(mapStateToProps, {
  postBuild,
  getBuild,
  deleteBuildImage,
  deleteBuildItem,
})(withRouter(EditBuild));
