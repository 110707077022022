import './App.css';

import React from 'react';
import Notice from './Notice';

import Drawer from './drawer';
import Dock from './dock';

import LandingPage from './landing/';
import NotFound from './404';
import LoginRouter from './auth';

import EditProfile from './profile/edit/';

import AthleteRouter from './athlete';
import BuildRouter from './builds';
import ItemRouter from './items';
import ListRouter from './lists';
import ApeRouter from './ape';

import { Routes, Route } from 'react-router-dom';
import CustomRouter from './customRouter';
import history from '../history';

import { connect } from 'react-redux';
import { fetchUser, getProfile, getLists, getNotifications } from '../actions';

import Banner from './banner';
import Support from './support';

import Feed from './feed';
import PostFeed from './feed/posts';
import Search from './search';
import ScrollToTop from './ui/scrollToTop';

import ParticipantModal from './builds/participantModal';

import Analytics from './analytics';
import Directory from './directory';

class App extends React.Component {
  componentDidMount() {
    this.props.fetchUser();
    this.props.getProfile();
    this.props.getNotifications();
    this.props.isSignedIn && this.props.getLists();

    // listen for app-drawer checkbox change
    document.getElementById('app-drawer').addEventListener('change', (e) => {
      this.drawer = e.target.checked;
      this.forceUpdate();
    });

    // add event listener to console log which element is currently scrolling
    // const onScroll = (e) => {
    //     // console log the element className, id, tagname, and scroll position
    //     console.log('scrolling', e.target, e.target.className, e.target.id, e.target.tagName, e.target.scrollTop);
    // }
    // document.addEventListener('scroll', onScroll, true);
    // return () => {
    //     document.removeEventListener('scroll', onScroll);
    // }
  }

  // on component update, check if the user is signed in and get their lists
  componentDidUpdate(prevProps) {
    if (this.props.isSignedIn && !prevProps.isSignedIn) {
      this.props.getLists();
    }
  }

  // TEMP - remove when live
  isAuth() {
    // if any property of loader is true except loader with name puusher, return false
    if (
      Object.values(this.props.loader).some((v) => v === true) &&
      !this.props.loader.pusher
    ) {
      return false;
    }
    return this.props.auth && this.props.auth.userId;
  }

  render() {
    const { builds, lists } = this.props;

    return (
      <>
        <Notice />
        <CustomRouter history={history}>
          <div className="drawer drawer-end">
            <input id="app-drawer" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content h-screen" id="main-content">
              <ScrollToTop />
              <Banner notice={this.props.updateNotice} />
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="404" element={<NotFound />} />
                <Route path="/" element={<LandingPage />} />
                <Route path="directory" element={<Directory />} />
                <Route path="login/*" element={<LoginRouter />} />

                <Route
                  path="feed"
                  feedType="featured"
                  element={<Feed lists={lists} />}
                />
                <Route path="feed/:feedType" element={<Feed lists={lists} />} />
                <Route
                  path="feed/:feedType/:filter"
                  element={<Feed lists={lists} />}
                />
                <Route path="discover" element={<PostFeed />} />
                <Route path="item/*" element={<ItemRouter lists={lists} />} />
                <Route path="profile/edit" element={<EditProfile />} />
                <Route
                  path="build/*"
                  element={<BuildRouter builds={builds} lists={lists} />}
                />
                <Route path="list/*" element={<ListRouter />} />
                <Route path="search" element={<Search />} />
                <Route path="search/:type/:term" element={<Search />} />
                <Route
                  path="athlete/:alias/*"
                  element={<AthleteRouter lists={lists} />}
                />
                <Route
                  path=":alias/*"
                  element={<AthleteRouter lists={lists} />}
                />
                <Route path="ape-admin/*" element={<ApeRouter />} />
              </Routes>
            </div>
            <ParticipantModal builds={this.props.builds} />
            <Drawer />
            <Analytics />
            <div className="spacer mb-30"></div>
            <Dock />
            <Support />
          </div>
        </CustomRouter>
      </>
    );
  }
}

function mapStateToProps(state) {
  const builds = state.builds && state.builds.builds ? state.builds.builds : {};

  // get lists and ignore list with listType 'liked'
  const processLists = () => {
    const lists = state.lists && state.lists ? state.lists : {};
    const listsArray = Object.values(lists);
    const filteredLists = listsArray.filter((list) => list.listType === 'list');
    const preppedLists =
      filteredLists?.length > 0
        ? filteredLists
        : { __new__: { _id: '__new__', name: 'My List', listType: 'list' } };

    return preppedLists;
  };
  const lists = processLists() || {};

  // use the updateNotice to notify users that there is an update available
  const updateNotice = state?.sw?.serviceWorkerUpdated
    ? 'Please refresh to update the app.'
    : '';

  return {
    auth: state.auth,
    loader: state.loader,
    athletedId: state?.profile?._id,
    isSignedIn: state.auth?.userId,
    updateNotice,
    builds,
    lists,
  };
}

export default connect(mapStateToProps, {
  fetchUser,
  getProfile,
  getLists,
  getNotifications,
})(App);
