import _ from 'lodash';
import React from 'react';

import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import Masonry from 'react-masonry-css';

import { getItem, deleteItem, showModal } from '../../../actions';

import Item from '../../ui/Item';
import ReadMore from '../../ui/ReadMore';

import Loader from '../../Loader';
import Share from '../../reactions/share';
import Bookmark from '../../reactions/bookmark';

import ItemImages from '../image';

import BuildCard from '../../builds/card';

import RecordEvent from '../../analytics/pageview';

import FamilyItemsModal from '../family';

import APArrowLeftIcon from '../../ui/ArrowLeftIcon';

import Helmet from '../../ui/Helmet';

import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import Glassup from '../../landing/glassup';
import Listings from './listings';

const ViewItem = ({
  items,
  getItem,
  deleteItem,
  isAdmin,
  showModal,
  lists,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    const { state } = location;
    const skipEdit = state ? state.skipEdit : false;
    if (skipEdit) {
      navigate(-3);
    } else {
      navigate(-1);
    }
  };

  // on mount, get item
  const [mount, setMount] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [viewMoreVariant, setViewMoreVariant] = React.useState(false);

  React.useEffect(() => {
    if (!mount || mount !== id) {
      setMount(id);
      getItem(id, true);
    }
  }, [mount, getItem, id]);

  // handle scroll to load more builds
  React.useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight +
          document.getElementById('main-content').scrollTop +
          400 <
        document.getElementById('scroll-surface').offsetHeight
      )
        return;

      // if page of current type is equal to total pages, or loader is true, or total pages is 0, return
      if (
        !items[id]?.totalBuildsPages ||
        page >= items[id]?.totalBuildsPages ||
        items[id]?.loader ||
        items[id]?.totalBuildsPages === 0
      )
        return;

      getItem(id, true, page + 1);

      setPage(page + 1);
    }

    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [page, setPage, items, id, getItem]);

  if (!items || !items[id]) {
    return <Loader type="full" />;
  }

  const item = items[id];
  const { name, description, _category } = items[id];
  const { _make } = item;

  function groupYears(years) {
    years.sort((a, b) => a - b);
    // convert to number
    years = years.map((year) => Number(year));
    const ranges = [];
    let start = years[0];

    for (let i = 1; i < years.length; i++) {
      if (years[i] !== years[i - 1] + 1) {
        ranges.push(
          start === years[i - 1] ? `${start}` : `${start}-${years[i - 1]}`
        );
        start = years[i];
      }
    }

    ranges.push(
      start === years[years.length - 1]
        ? `${start}`
        : `${start}-${years[years.length - 1]}`
    );
    return ranges;
  }

  // get family object
  const familyObject = {
    name: item?._family?.name,
    familyItems: item?.familyItems?.sort((a, b) => {
      return a._category?.name?.localeCompare(b._category?.name);
    }),
  };

  const renderBuildsWithItem = () => {
    const { builds } = item;

    if (builds && !_.isEmpty(builds)) {
      return (
        <div className="flex flex-col">
          <h2 className="text-center my-5">
            <span className="font-medium">
              {familyObject?.familyItems?.length > 1
                ? `${_make.name} ${familyObject?.name} `
                : `${_make.name} ${name} `}
            </span>
            included in these builds
          </h2>
          <Glassup>
            <Masonry
              breakpointCols={{ 640: 1, default: 3 }}
              className="masonry-grid gap-4 md:gap-8 lg:gap-16"
              columnClassName="masonry-grid_column"
            >
              {builds.map((build, index) => {
                return <BuildCard key={index} build={build} lists={lists} />;
              })}
            </Masonry>
          </Glassup>
        </div>
      );
    }
  };

  const renderMake = () => {
    const { athlete, _make } = item;

    // if no athlete, return null
    const renderLink = athlete?.alias
      ? `/${athlete?.alias}/`
      : `/athlete/${athlete?._id}/`;

    return (
      <div className="flex flex-col justify-between border-b border-gray-200 py-2">
        <div className="inline-flex">
          <span className="capitalize text-sm pr-1 inline-flex">Make:</span>
          {athlete?._id ? (
            <Link to={renderLink} className="text-sm font-medium">
              {_make?.name}
            </Link>
          ) : (
            <span className="text-sm">{_make?.name}</span>
          )}
        </div>
      </div>
    );
  };

  const renderMenu = () => {
    const { state } = location;
    const backable = state ? state.backable : false;

    return (
      <div className="flex items-center justify-between my-2 ml-3 sm:mx-0 lg:flex-col">
        {backable && (
          <Link
            className="btn btn-square btn-ghost"
            onClick={() => handleBackButton()}
          >
            <APArrowLeftIcon />
          </Link>
        )}
      </div>
    );
  };

  const renderEditButton = () => {
    return (
      isAdmin && (
        <li>
          <Link to={`/item/edit/${id}`} state={{ backable: true }}>
            Edit
          </Link>
        </li>
      )
    );
  };

  const handleDelete = () => {
    // confirm
    const confirm = window.confirm(`Are you sure you want to delete ${name}?`);
    if (confirm) {
      deleteItem(id);
    }
  };

  const renderDeleteButton = () => {
    return (
      isAdmin && (
        <li>
          <span onClick={handleDelete}>Delete</span>
        </li>
      )
    );
  };

  const renderVariants = () => {
    const { variants } = item;

    // on click, toggle viewMoreVariant
    const onViewMoreVariant = (variantType) => {
      if (viewMoreVariant === variantType) {
        setViewMoreVariant(false);
      } else {
        setViewMoreVariant(variantType);
      }
    };

    // group and display by variantType
    let groupedVariants = _.groupBy(variants, 'variantType');

    // sort grouped variants of type year by year
    if (groupedVariants['year']) {
      groupedVariants['year'].sort((a, b) => {
        return a.variant - b.variant;
      });
    }

    // replace existing groupedVariants['year'] with grouped years
    if (groupedVariants['year']) {
      const years = groupedVariants['year'].map((year) => year.variant);
      const groupedYears = groupYears(years);
      const groupYearPair = groupedYears.map((year) => {
        return { variant: year, variantType: 'year' };
      });

      groupedVariants['year'] = groupYearPair;
    }

    // for variantType size, if sizes are abreviated, capitalize
    if (groupedVariants['size']) {
      groupedVariants['size'].forEach((size) => {
        if (size.variant.length <= 3) {
          size.variant = size.variant.toUpperCase();
        }
      });
    }

    return Object.keys(groupedVariants).map((variantType, i) => {
      const variantTypeVariants = groupedVariants[variantType];

      if (
        groupedVariants['undefined'] ||
        groupedVariants['null'] ||
        groupedVariants['']
      ) {
        return (
          <div
            className="flex flex-col justify-between border-b border-gray-200 py-2"
            key={i}
          >
            <div className="flex flex-row flex-wrap items-center gap-1">
              <Loader type="placeholder" />
            </div>
          </div>
        );
      }
      return (
        <div
          key={i}
          className="flex flex-col justify-between border-b border-gray-200 dark:border-gray-200 py-2"
        >
          <div className="inline-flex">
            <span className="capitalize text-sm pr-1 inline-flex">
              {variantType}:
            </span>
            <span
              className={`text-sm capitalize hover:cursor-pointer ${
                viewMoreVariant && viewMoreVariant === variantType
                  ? `line-clamp-none`
                  : `line-clamp-1`
              }`}
              onClick={() => onViewMoreVariant(variantType)}
            >
              {variantTypeVariants.map((variant, i) => {
                return (
                  <span key={i}>
                    {variant.variant}
                    {i < variantTypeVariants.length - 1 ? ', ' : ''}
                  </span>
                );
              })}
            </span>
          </div>
        </div>
      );
    });
  };

  // error message
  // if category doesn't have parent family, display error message
  const renderError = () => {
    if (_category && !_category?._parent?.name) {
      return (
        <div className="alert alert-warning my-2 text-xs p-3 gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-5 w-5 self-start mt-1"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>
            Item category needs to be created or confirmed. Please edit and
            select a category.{' '}
          </span>
        </div>
      );
    }
  };

  // create link to category search
  const renderCategory = () => {
    if (_category?.name) {
      const categoryName = _category.name;
      // create slug, replace spaces and special characters with dashes and lowercase
      const slug = categoryName
        .replace(/[^a-zA-Z0-9- ]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase();
      const categoryPath = `/search/items/category:${_.toLower(slug)}`;

      return (
        <Link to={categoryPath}>
          {
            // if category parent is frame, append 'frames' to category name
            _category._parent?.name === 'Frame'
              ? `${categoryName} Frame`
              : categoryName
          }
        </Link>
      );
    }
  };

  // render family name
  const renderFamilyName = () => {
    if (item?._family?.name) {
      const familyName = item._family.name;

      // if category parent is frame, append 'frames' to category name
      const categoryName =
        _category._parent?.name === 'Frame'
          ? `${_category?.name} Frames`
          : _category?.name;

      return (
        <div
          className="text-sm hover:cursor-pointer text-secondary"
          onClick={() => showModal('FAMILY_ITEMS')}
        >
          {familyName} {categoryName}
        </div>
      );
    }
  };

  // get first image of item, if no image, return null
  const firstImage =
    item?.images?.length > 0 ? Object.values(item.images)[0].imageFile : null;

  return (
    <>
      <Item>
        <Helmet
          title={`${_make?.name} ${name} on Active Projects`}
          description={description}
          image={firstImage}
          url={`${process.env.REACT_APP_URL}/item/${id}`}
        />
        <div className="flex flex-col order-1 w-fit">{renderMenu()}</div>
        <div className="flex flex-col w-full sm:flex-row lg:mt-3 order-last lg:order-2 lg:w-10/12 gap-4 items-center max-w-screen-lg">
          <div className="flex flex-wrap sm:flex-row w-full">
            <div className="w-full sm:w-6/12">
              <ItemImages item={item} />
            </div>
            <div className="px-4 sm:px-8 w-full sm:w-6/12">
              <div className="flex items-center justify-between -ml-1 mb-2">
                <div className="flex items-center space-x-1 mt-2 sm:mt-0">
                  <Bookmark item={item} lists={lists} right />
                </div>
                <div className="flex items-center space-x-1 mt-2 sm:mt-0">
                  <Share item={item} />
                </div>
              </div>
              {isAdmin && renderError()}
              <div className="capitalize text-secondary font-normal text-sm">
                {renderCategory()}
              </div>
              <h1>
                {_make.name} {name}
              </h1>
              {renderFamilyName()}
              {description && (
                <ReadMore className="my-3">{description}</ReadMore>
              )}
              <div className="my-2">
                {renderMake()}
                {renderVariants()}
              </div>
              {/* <div className="mb-2">{renderSuggest()}</div> */}
              <Listings listings={item?.listings} />
              <div className="flex flex-col mt-4 justify-between">
                <h2>Find Local</h2>
                <p>
                  Own or manage a local bike shop that carries this make? Please
                  reach out to us to get started.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col order-2 lg:order-3 items-center">
          <div className="dropdown dropdown-bottom dropdown-end mr-4 sm:-mr-1 sm:pr-0">
            <label
              tabIndex={0}
              className="btn btn-square btn-ghost self-center cursor-pointer  mt-2"
            >
              <EllipsisHorizontalIcon className="h-10 w-10" />
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu shadow bg-base-200 rounded-box w-32"
            >
              {renderEditButton()}
              {renderDeleteButton()}
              {!isAdmin && (
                <li>
                  <div
                    onClick={() =>
                      showModal('SUPPORT', {
                        comment: `Suggesting an edit to ${_make?.name} ${name}`,
                      })
                    }
                  >
                    Suggest Edit
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        <FamilyItemsModal family={familyObject} />
      </Item>
      <RecordEvent
        hitType="pageview"
        page={window.location.pathname}
        title={`Item - ${_make?.name} ${name}`}
      />
      <div className="flex flex-wrap sm:flex-row">
        <div className="px-4 sm:px-0 w-full my-4">{renderBuildsWithItem()}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const athlete =
    state.athlete && state.athlete[state.auth.athlete]
      ? state.athlete[state.auth.athlete]
      : {
          name: state.auth.name,
          email: state.auth.email,
          id: '_new_',
        };

  const isAdmin = state?.auth?.permissions?.admin;

  return {
    auth: state.auth,
    athlete: athlete,
    items: state.items?.items ? state.items.items : null,
    isAdmin,
  };
};

export default connect(mapStateToProps, { getItem, deleteItem, showModal })(
  ViewItem
);
