// react component to render item listings
import _ from 'lodash';
import React from 'react';

import {
  // ShoppingCartIcon,
  ArrowTopRightOnSquareIcon,
  ChatBubbleLeftIcon,
} from '@heroicons/react/24/solid';

import AthleteLine from '../../../athlete/line';

function Price({ value }) {
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

  return <div>{formattedPrice}</div>;
}

const Listings = ({ listings }) => {
  if (listings && !_.isEmpty(listings)) {
    // if an object, convert to array
    const listingsArray = Array.isArray(listings)
      ? listings
      : Object.values(listings);

    // if no listings, return null
    if (listingsArray.length === 0) return null;

    // if no listing has URI, return null
    const noURI = listingsArray.every((listing) => !listing.URI);
    if (noURI) return null;

    return (
      <div className="flex flex-col mt-5">
        <h2>Buy now</h2>
        {listingsArray.map((listing, i) => {
          return (
            <div key={listing._id || i} className="flex flex-col">
              {listing.URI && (
                <a
                  href={listing.URI}
                  target="_blank"
                  rel="noreferrer"
                  className="flex flex-col justify-between items-start border-b border-gray-200 py-1 hover:border-gray-500 gap-0"
                >
                  <div className="truncate overflow-clip text-sm w-full">
                    {listing?.name}
                  </div>
                  <div className="flex flex-row gap-3 w-full  justify-between">
                    <div className="flex">
                      <Price value={listing.price} />
                    </div>
                    <div className="flex items-center gap-3">
                      <div>
                        <AthleteLine
                          athlete={listing._athlete}
                          className="text-sm"
                          noLink={true}
                          noAvatar={true}
                        />
                      </div>
                      <div>
                        <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                      </div>
                    </div>
                  </div>
                </a>
              )}
              {!listing.URI && (
                <div className="flex flex-row justify-between border-b border-gray-200 py-2">
                  <AthleteLine athlete={listing._athlete} className="text-sm" />
                  <div className="flex flex-row items-center gap-3">
                    <div>{listing?.name}</div>
                  </div>
                  <div className="flex flex-row items-center gap-3">
                    <Price value={listing.price} />
                    <button className="btn btn-square btn-sm btn-outline">
                      <ChatBubbleLeftIcon className="h-6 w-6" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default Listings;
