import _ from 'lodash';
import { 
    GET_ATHLETE,
    POST_ATHLETE,
    DELETE_ATHLETE,
    DELETE_ATHLETE_IMAGE
} from '../actions/types';

const INITIAL_STATE = {};

const athleteReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ATHLETE:
            return { 
                [action.payload.alias || action.payload._id]: action.payload
            };
        case POST_ATHLETE:
            return {
                [action.payload.alias || action.payload._id]: action.payload
            }
        case DELETE_ATHLETE:
            return _.omit(state.athlete, action.payload);
        case DELETE_ATHLETE_IMAGE:
            return { [action.payload.alias || action.payload.id]: {
                ...state[action.payload.alias || action.payload.id],
                // remove the image from the images array
                images: _.remove
                // iterate over the images array
                (state[action.payload.alias || action.payload.id].images, (image) => image.imageFile !== action.payload.imageFile)
            }};
        default:
            return state;
    }
};

export default athleteReducer;