const parseLinks = (text) => {
    // regex to find urls
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    // split text into array of strings and urls
    const textArray = text.split(urlRegex);
    // map over array and return text or link
    return textArray.map((item,index) => {
        if (item.match(urlRegex)) {
            // get domain name excluding www
            const domain = item.replace('https://','').replace('http://','').replace('www.','').split(/[/?#]/)[0];
            return (
                <a
                    key={index}
                    href={item}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-500 hover:underline"
                >
                    {domain}
                </a>
            );
        }
        return item;
    });
}

export default parseLinks;