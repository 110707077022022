import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Page from '../../ui/Page';
import { useNavigate } from 'react-router-dom';

import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import CustomInput from '../../forms/CustomInput';
import CustomTextArea from '../../forms/CustomTextarea';
import CustomDropdown from '../../forms/CustomDropdown';
import CustomSelectMulti from '../../forms/CustomSelectMulti';
import LiveCheckInput from '../../forms/LiveCheckInput';
import ImageUpload from '../../forms/ImageUpload';

import Header from '../../header';
import Build from '../../ui/Build';

import Helmet from '../../ui/Helmet';

// import heroicon of back arrow
import APArrowLeftIcon from '../../ui/ArrowLeftIcon';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { connect } from 'react-redux';
import {
  postAthlete,
  deleteProfileImage,
  searchMake,
  searchUsers,
  searchAlias,
  deleteAthlete,
  showModal,
} from '../../../actions';
import Loader from '../../Loader';

import serviceTypes from '../../../utils/serviceTypes.json';

const focusOnError = createDecorator();

const EditProfile = (props) => {
  const [makeOptions, setMakeOptions] = useState(props.makes);
  const [userOptions, setUserOptions] = useState(props.userOptions);

  const navigate = useNavigate();

  // listen for changes to props.makes
  useEffect(() => {
    setMakeOptions(props.makes);
  }, [props.makes]);

  // listen for changes to props.userOptions
  useEffect(() => {
    setUserOptions(props.userOptions);
  }, [props.userOptions]);

  const onDelete = async () => {
    if (window.confirm('Are you sure you want to delete this profile?')) {
      await props.deleteAthlete(props.athlete._id);
    }
  };

  // render delete button if user is an admin
  const renderDeleteButton = () => {
    if (props.isAdmin) {
      return (
        <div className="flex flex-col mt-8 py-4 border-t gap-4">
          <div className="text-error font-semibold">Danger Zone</div>
          <div
            className={`btn btn-error btn-outline flex-row w-32`}
            onClick={onDelete}
          >
            <span>Delete</span>
          </div>
        </div>
      );
    }
  };

  const onSubmit = async (formValues) => {
    // return promise
    return await props.postAthlete(formValues);
  };

  const handleDeleteImage = async (imageFile) => {
    await props.deleteProfileImage(imageFile);
  };

  // does this athlete have a user with an identity field of true?
  const hasIdentity = () => {
    if (!props.athlete.users) {
      return false;
    }
    return props.athlete.users.some((user) => user.identity);
  };

  const handleAliasSearch = async (searchTerm) => {
    // debounce search
    await props.searchAlias(searchTerm);
  };

  const renderForm = () => {
    const initialValues = {
      ...props.athlete,
      notifications: {
        email: props.userNotifications?.email,
        newsletter: props.userNotifications?.newsletter,
      },
      users: props.athleteUsers,
    };

    // filter serviceTypes to only include those in the current profile type value
    function filterServiceTypes(profileType) {
      if (!profileType) {
        return [];
      }
      const newTypes = serviceTypes.map((serviceType) => {
        if (
          serviceType.value === profileType ||
          (_.isArray(serviceType.value) &&
            _.includes(serviceType.value, profileType))
        ) {
          return serviceType;
        } else {
          return [];
        }
      });
      // remove empty arrays
      _.remove(newTypes, (n) => _.isEmpty(n));
      return newTypes;
    }

    return (
      <>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          initialValuesEqual={() => true} // prevent reinitializing form on every render
          decorators={[focusOnError]}
          validate={(formValues) => {
            const errors = {};

            if (!formValues.name) {
              errors.name = 'What should we call you?';
            }

            // if (!formValues.email) {
            //     errors.email = "What's your email?";
            // }

            // // validate email
            // if (!validateEmail(formValues.email)) {
            //     errors.email = "Please enter a valid email address.";
            // }

            if (formValues.alias) {
              // regex to check for spaces or special characters, except for hyphens and underscores
              const regex = /[^a-zA-Z0-9-_]/g;
              if (regex.test(formValues.alias)) {
                errors.alias =
                  'Your alias cannot contain spaces or special characters.';
              }
              if (
                props.search?.alias?.alias === formValues.alias &&
                props.search?.alias?.aliases?.length > 0
              ) {
                errors.alias = 'This alias is already taken.';
              }
            }

            if (!formValues.alias) {
              errors.alias = 'Please enter a unique word for your link.';
            }

            return errors;
          }}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
            setOptions: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <>
              <form onSubmit={handleSubmit} className="w-full mt-4 sm:mt-8">
                <div className="flex flex-col sm:flex-row justify-between w-full">
                  <div className="flex flex-col order-1 w-14 flex-none">
                    <div
                      onClick={() => {
                        if (form.getState().dirty) {
                          if (
                            window.confirm(
                              'Are you sure you want to discard your changes?'
                            )
                          ) {
                            navigate(-1);
                          }
                        } else {
                          navigate(-1);
                        }
                      }}
                      className="btn btn-ghost -mt-1"
                    >
                      <APArrowLeftIcon />
                    </div>
                  </div>
                  <div className="flex flex-col grow order-2 items-center">
                    <div className="w-full sm:max-w-xl px-4 sm:px-0">
                      <div className="flex flex-col">
                        <h1>Edit Profile</h1>
                      </div>
                      <div className="flex flex-col my-4">
                        <Field
                          name="name"
                          component={CustomInput}
                          type="text"
                          placeholder="Name"
                          label="Name"
                        />
                      </div>
                      <div className="flex flex-col my-4">
                        <Field
                          name="profileImages"
                          component={ImageUpload}
                          label="Profile image"
                          handleDeleteImage={handleDeleteImage}
                          desiredWidth={500}
                          desiredHeight={500}
                          maxImages={1}
                          previewSize="sm"
                          desiredAspectRatio={1}
                          circular
                          required={false}
                        />
                      </div>
                      <div className="flex flex-col my-4">
                        <Field
                          name="bio"
                          component={CustomTextArea}
                          type="text"
                          placeholder="Bio"
                          label="Bio"
                        />
                      </div>
                      <div className="flex flex-col my-4">
                        <Field
                          name="location"
                          component={CustomInput}
                          type="text"
                          placeholder="Location"
                          label="Location"
                        />
                      </div>
                      <div className="flex flex-row my-4">
                        <Field
                          name="alias"
                          component={LiveCheckInput}
                          type="text"
                          placeholder="alias"
                          prefixPlaceholder="activeprojects.co/"
                          label="Your unique link"
                          searchAction={handleAliasSearch}
                          parse={(value) => value.toLowerCase()}
                        />
                      </div>
                      <div className="flex flex-col my-4">
                        <Field
                          name="url"
                          component={CustomInput}
                          type="text"
                          placeholder="https://www.example.com"
                          label="Website"
                        />
                      </div>
                      <div className="flex flex-col my-4">
                        <Field
                          name="notifications.email"
                          component={CustomDropdown}
                          label="Notification frequency of comments and likes"
                          tooltip="How often would you like to receive email notifications about activity on your builds and lists?"
                          options={[
                            { label: 'Daily', value: 'daily' },
                            { label: 'Weekly', value: 'weekly' },
                            { label: 'Never', value: 'never' },
                          ]}
                        />
                      </div>
                      {props.isIdentity && (
                        <div className="flex flex-col my-4">
                          <Field
                            name="notifications.newsletter"
                            component={CustomDropdown}
                            label="Newsletter frequency"
                            tooltip="How often would you like to receive the Active Projects newsletter?"
                            options={[
                              { label: 'Weekly', value: 'weekly' },
                              { label: 'Never', value: 'never' },
                            ]}
                          />
                        </div>
                      )}
                      {props.isAdmin && (
                        <>
                          <div className="divider font-semibold">Admin</div>
                          <div className="flex flex-col my-4">
                            <Field
                              name="profileType"
                              component={CustomDropdown}
                              label="Profile type"
                              options={[
                                { label: 'Athlete', value: 'athlete' },
                                { label: 'Company', value: 'company' },
                                { label: 'Group', value: 'group' },
                                { label: 'Event', value: 'event' },
                              ]}
                            />
                          </div>

                          <div className="flex flex-col my-4">
                            <Field
                              name="services"
                              component={CustomSelectMulti}
                              // options={(values) => filterServiceTypes(values.profileType) || serviceTypesFiltered}
                              options={filterServiceTypes(values.profileType)}
                              defaultOptions={filterServiceTypes(
                                values.profileType
                              )}
                              // defaultOptions={serviceTypesFiltered}
                              label="Services offered"
                              tooltip="Besides riding your bike, do you also contribute to builds in other ways? eg. Photographer, Mechanic, Trainer..."
                              searchAction={(searchTerm) => {
                                // console.log('searching for', searchTerm);
                              }}
                              placeholder="What services do you offer?"
                              isMulti
                              isCreatable={false}
                            />
                          </div>
                          {values.profileType === 'company' && (
                            <div className="flex flex-col my-4">
                              <Field
                                name="makes"
                                component={CustomSelectMulti}
                                label="Makes"
                                options={makeOptions}
                                defaultOptions={props.athlete.makes}
                                searchAction={props.searchMake}
                                placeholder="Select makes owned by this company"
                                isMulti
                                isCreatable={false}
                              />
                            </div>
                          )}
                          {
                            <div className="flex flex-col my-4">
                              <Field
                                name="users"
                                component={CustomSelectMulti}
                                label="Users"
                                options={userOptions}
                                defaultOptions={props.athleteUsers}
                                searchAction={props.searchUsers}
                                placeholder="Select users with access to this profile"
                                isMulti
                                isCreatable={false}
                                minOptions={1}
                              />
                            </div>
                          }
                          <div className="flex flex-col my-4">
                            <Field
                              name="status"
                              component={CustomDropdown}
                              label="Verification status"
                              options={[
                                { label: 'Unverified', value: 'unverified' },
                                { label: 'Verified', value: 'verified' },
                                { label: 'Hidden', value: 'hidden' },
                              ]}
                            />
                          </div>
                        </>
                      )}
                      <div>
                        <Field
                          name="id"
                          component={CustomInput}
                          type="hidden"
                        />
                      </div>
                      <div className="flex flex-col">
                        <button
                          className={`btn btn-primary flex-row w-32`}
                          type="submit"
                          disabled={submitting || pristine}
                        >
                          <span
                            className={
                              submitting
                                ? 'loading loading-spinner loading-sm flex'
                                : 'hidden'
                            }
                          />
                          {submitting ? 'Saving' : 'Save'}
                        </button>
                      </div>
                      {renderDeleteButton()}
                    </div>
                  </div>
                  <div className="flex flex-col sm:order-3 order-none sm:w-14 flex-none"></div>
                </div>
              </form>
            </>
          )}
        />
      </>
    );
  };

  const renderIdentityWarning = () => {
    return (
      <div className="flex flex-col mt-4 mb-6 px-4">
        <div className="alert alert-warning shadow-lg">
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-row items-center font-semibold text-left">
              <ExclamationCircleIcon className="h-7 w-7 mr-2" />
              This account does not have a primary user.
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (
    props?.setup !== true &&
    (_.isEmpty(props.athlete) || !props.userOptions?.length)
  ) {
    return <Loader />;
  }
  return (
    <Page className="">
      <Helmet title="Edit Profile | Active Projects" />
      <Header />
      <div className="flex flex-col w-full pt-15">
        {props.isAdmin && !hasIdentity() && renderIdentityWarning()}

        <Build>{renderForm()}</Build>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const athlete =
    state.auth.profile &&
    state.auth.athlete &&
    state.auth.profile._id === state.auth.athlete
      ? state.auth.profile
      : {
          name: state.auth.name,
          email: state.auth.email,
          id: '_new_',
        };

  // is user an admin?
  const isAdmin = state?.auth?.permissions?.admin;

  // compact removes null and undefined values
  const makesResults = state?.search?.makes
    ? _.compact(
        Object.values(state.search.makes).map(
          (make) =>
            make?.name &&
            make?._id && {
              label: make.name,
              value: make._id,
            }
        )
      )
    : [];

  // get athlete makes only if they exist and are not empty
  const athleteMakes =
    athlete?.makes && !_.some(athlete?.makes, (make) => _.isEmpty(make))
      ? athlete.makes
      : [];
  const makes = _.uniqBy([...makesResults, ...athleteMakes], 'value');

  // get users on the athlete and add them to the options as label/value pairs
  const athleteUsers = athlete?.users
    ? Object.values(athlete.users).map((user) => ({
        // set the current user as isFixed
        isFixed: user._userId?._id === state.auth.userId,
        label: user._userId?.displayName
          ? `${user._userId?.displayName} <${user._userId?.email}>`
          : 'Invite Pending',
        value: user._userId?._id || user.inviteToken,
      }))
    : [];

  // get notifications preference for current user in the athlete
  const userNotifications =
    athlete?.users?.find((user) => user._userId?._id === state.auth.userId)
      ?.notifications || {};

  // get users from the search results and add them to the options as label/value pairs
  const userResults = state?.search?.user
    ? Object.values(state.search.user).map((user) => ({
        isFixed: user._id === state.auth.userId,
        label: `${user.displayName} <${user.email}>`,
        value: user._id,
      }))
    : [];
  // combine the two arrays and remove duplicates
  const userOptions = _.uniqBy([...athleteUsers, ...userResults], 'value');

  const setup = state?.auth?.profile?.step === 'setup_athlete';

  const isIdentity = state.auth.profile?.users?.find(
    (user) => user?.identity && user?._userId?._id === state?.auth?.userId
  )
    ? true
    : false;

  return {
    auth: state.auth,
    isAdmin,
    isIdentity,
    athlete,
    makes,
    athleteUsers,
    userOptions,
    userNotifications,
    setup,
    search: state.search,
    loader: state.loader,
  };
};

export default connect(mapStateToProps, {
  postAthlete,
  deleteProfileImage,
  deleteAthlete,
  searchMake,
  searchUsers,
  searchAlias,
  showModal,
})(EditProfile);
