import React from 'react';
import DOMPurify from 'dompurify';

const PostParagraph = ({ children }) => {
  const cleanChildren = DOMPurify.sanitize(children, {
    ALLOWED_TAGS: [
      'b',
      'i',
      'em',
      'strong',
      'br',
      'ul',
      'li',
      'ol',
      'blockquote',
      'p',
    ],
  });

  // replace <p></p> tags with <div></div> tags
  const replacePTags = cleanChildren;

  return (
    <div
      className="text-md col-span-full flex flex-col pb-1"
      dangerouslySetInnerHTML={{ __html: replacePTags }}
    />
  );
};

export default PostParagraph;
