import _ from 'lodash';
import React from 'react';

import SearchBar from './bar';
import BuildCard from '../builds/card';
import ItemCard from '../items/card';
import AthleteCard from '../athlete/card';
import ListCard from '../lists/listCards';

import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { search, showModal } from '../../actions';

import Header from '../header';
import Page from '../ui/Page';
import Loader from '../Loader';

import RecordEvent from '../analytics/pageview';

import FamilyItemsModal from '../items/family';

import Masonry from 'react-masonry-css';

import Helmet from '../ui/Helmet';
import Glassup from '../landing/glassup';

const Search = ({ search, searchResults, loader, showModal }) => {
  const { term, type } = useParams();

  const [page, setPage] = React.useState({
    builds: 1,
    items: 1,
    athletes: 1,
    lists: 1,
  });

  const {
    builds,
    items,
    athletes,
    lists: listResults,
    term: prevTerm,
  } = searchResults;

  const [mount, setMount] = React.useState(false);
  const [selectedFamily, setSelectedFamily] = React.useState(null);
  const [familyObject, setFamilyObject] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState(term);

  React.useEffect(() => {
    if (!mount && term && type) {
      setMount(true);
      // don't search if term was already searched
      if (term !== prevTerm) {
        search(term, type, 1);
      }

      // search(term, type, 1);
    }
  }, [mount, search, term, type, page, prevTerm]);

  // if term or type changes, reset page
  React.useEffect(() => {
    if (term !== searchTerm) {
      setSearchTerm(term);
      setPage({
        builds: 1,
        items: 1,
        athletes: 1,
        lists: 1,
      });
    }
  }, [term, type, searchTerm]);

  // get the next page of items if the user scrolls to the bottom of the page on the items tab
  React.useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight +
          document.getElementById('main-content').scrollTop +
          400 <
        document.getElementById('scroll-surface').offsetHeight
      )
        return;

      // if page of current type is equal to total pages, or loader is true, or total pages is 0, return
      if (
        page[type] >= searchResults[type]?.totalPages ||
        loader ||
        searchResults[type]?.totalPages === 0
      )
        return;

      search(term, type, page[type] + 1);

      const newPage = {
        ...page,
        [type]: page[type] + 1,
      };

      setPage(newPage);
    }
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [page, setPage, loader, term, type, search, searchResults]);

  const handleSelectFamily = (item) => {
    const familyItems = [
      item,
      ...item?.familyItems
        ?.filter((i) => i._id !== item._id)
        .sort((a, b) => {
          return a._category?.name?.localeCompare(b._category?.name);
        }),
    ];
    const familyId = item?._family?._id;
    const name = item?._family?.name;
    setFamilyObject({
      name,
      familyItems,
    });
    setSelectedFamily(familyId);
    showModal('FAMILY_ITEMS');
  };

  const renderSearchResults = (term, type) => {
    if (type === 'builds' || type === 'q') {
      if (!builds?.paginatedBuilds?.length === 0 && !loader) {
        return <div className="flex w-full justify-center">No results</div>;
      }
      return (
        <>
          <Helmet
            title={`Search - ${term} builds | Active Projects`}
            description={`Search results for ${term} builds on Active Projects.`}
            url={`${process.env.REACT_APP_URL}/search/builds/${term}`}
          />
          <RecordEvent
            hitType="pageview"
            page={window.location.pathname}
            title={`Search builds - ${term}`}
          />
          <Glassup>
            <Masonry
              breakpointCols={{ 640: 2, 1280: 3, default: 5 }}
              className="masonry-grid gap-4 md:gap-8 lg:gap-8 px-4 md:px-0"
              columnClassName="masonry-grid_column"
            >
              {Object.values(builds?.paginatedBuilds)?.map((build, i) => {
                return <BuildCard key={i} build={build} noReactions />;
              })}
            </Masonry>
          </Glassup>
        </>
      );
    }

    if (type === 'items') {
      if (!items?.paginatedItems?.length === 0 && !loader) {
        return <div className="flex w-full justify-center">No results</div>;
      }

      return (
        <>
          <Helmet
            title={`Search - ${term} items | Active Projects`}
            description={`Search results for ${term} items on Active Projects.`}
            url={`${process.env.REACT_APP_URL}/search/items/${term}`}
          />
          <RecordEvent
            hitType="pageview"
            page={window.location.pathname}
            title={`Search items - ${term}`}
          />
          <div className="grid grid-cols-2 sm:grid-cols-4 w-full sm:space-y-0 gap-4 grid-flow-row auto-rows-max">
            {Object.values(items?.paginatedItems)?.map((item, i) => {
              if (item?.familyItems?.length > 1) {
                return (
                  <ItemCard
                    key={i}
                    item={item}
                    family
                    onClickHandler={() => handleSelectFamily(item)}
                  />
                );
              }
              return <ItemCard key={i} item={item} />;
            })}
          </div>
        </>
      );
    }

    if (type === 'profiles') {
      if ((!athletes || athletes.length === 0) && !loader) {
        return <div className="flex w-full justify-center">No results</div>;
      }
      return (
        <>
          <Helmet
            title={`Search - ${term} profiles | Active Projects`}
            description={`Search results for ${term} profiles on Active Projects.`}
            url={`${process.env.REACT_APP_URL}/search/profiles/${term}`}
          />
          <RecordEvent
            hitType="pageview"
            page={window.location.pathname}
            title={`Search profiles - ${term}`}
          />
          <Glassup>
            <div className="flex items-start gap-8 place-content-center w-full">
              {athletes.map((athlete, i) => {
                const athlink = athlete?.alias
                  ? `/${athlete.alias}/`
                  : `/athlete/${athlete._id}`;
                return (
                  <div className="flex flex-col items-center" key={i}>
                    <Link to={athlink}>
                      <AthleteCard athlete={athlete} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </Glassup>
        </>
      );
    }

    if (type === 'lists') {
      if (
        (!listResults?.totalLists || listResults?.totalLists === 0) &&
        !loader
      ) {
        return <div className="flex w-full justify-center">No results</div>;
      }
      const listArray = Object.values(listResults?.paginatedLists);
      return (
        <>
          <Helmet
            title={`Search - ${term} lists | Active Projects`}
            description={`Search results for ${term} lists on Active Projects.`}
            url={`${process.env.REACT_APP_URL}/search/lists/${term}`}
          />
          <RecordEvent
            hitType="pageview"
            page={window.location.pathname}
            title={`Search lists - ${term}`}
          />
          <Glassup>
            <div className="flex flex-row w-full sm:space-y-0 gap-4 mx-4">
              <ListCard lists={listArray} showProfiles={true} twoColumn />
            </div>
          </Glassup>
        </>
      );
    }
  };

  if (!term || !type) {
    return (
      <Page>
        <Header />
        <div className="flex flex-row justify-center pt-2 w-full sm:invisible">
          <SearchBar autoFocus />
        </div>
      </Page>
    );
  }

  if (!builds && !items && !athletes && !listResults) {
    return (
      <Page>
        <Header />
        <div className="flex flex-row justify-center pt-2 w-full sm:invisible">
          <SearchBar />
        </div>
        <div className="flex justify-center">
          <h1 className="text-2xl">No results</h1>
        </div>
      </Page>
    );
  }

  return (
    <Page>
      <Header />
      <div className="flex flex-row justify-center pt-2 w-full sm:hidden">
        <SearchBar />
      </div>
      <div>
        {loader ? (
          <div className="flex flex-row justify-center my-8"></div>
        ) : (
          <div className="tabs flex justify-center my-6 whitespace-nowrap max-w-s flex-nowrap px-5 py-1 overflow-y-visible overflow-x-scroll">
            <Link
              className={`tab text-xl sm:pl-0 ${
                type === 'builds' || type === 'q' ? 'tab-active font-bold' : ''
              }`}
              to={`/search/builds/${term}`}
              disabled={_.isEmpty(builds.paginatedBuilds) ? 'disabled' : ''}
            >
              {!loader
                ? builds.totalBuilds > 1
                  ? `${builds.totalBuilds} Builds`
                  : builds.totalBuilds === 1
                  ? `${builds.totalBuilds} Build`
                  : 'Builds'
                : 'Loading'}
            </Link>
            <Link
              className={`tab text-xl ${
                type === 'items' ? 'tab-active font-bold' : ''
              }`}
              to={`/search/items/${term}`}
              disabled={_.isEmpty(items.paginatedItems) ? 'disabled' : ''}
            >
              {items?.totalItems > 1
                ? `${items.totalItems} Items`
                : items?.totalItems === 1
                ? `${items.totalItems} Item`
                : 'Items'}
            </Link>
            <Link
              className={`tab text-xl ${
                type === 'profiles' ? 'tab-active font-bold' : ''
              }`}
              to={`/search/profiles/${term}`}
              disabled={athletes.length === 0 ? 'disabled' : ''}
            >
              {athletes.length > 1
                ? `${athletes.length} Profiles`
                : athletes.length === 1
                ? `${athletes.length} Profile`
                : 'Profiles'}
            </Link>
            <Link
              className={`tab text-xl ${
                type === 'lists' ? 'tab-active font-bold' : ''
              }`}
              to={`/search/lists/${term}`}
              disabled={_.isEmpty(listResults.paginatedLists) ? 'disabled' : ''}
            >
              {listResults?.totalLists > 1
                ? `${listResults.totalLists} Lists`
                : listResults?.totalLists === 1
                ? `${listResults.totalLists} List`
                : 'Lists'}
            </Link>
          </div>
        )}
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between w-full">
          {renderSearchResults(term, type)}
        </div>
      </div>
      {selectedFamily && <FamilyItemsModal family={familyObject} />}
      {loader && <Loader type="items" />}
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { term, type } = useParams;

  return {
    searchResults: state.search,
    term,
    type,
    loader: state.loader.search,
  };
};

export default connect(mapStateToProps, { search, showModal })(Search);
